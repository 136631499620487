<template>
  <div>
    <portal to="page-top-title">Badwords</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'badwords-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="Badwords" url="/badwords" :url-query="{ with: ['category:id,name'] }" data-prop="badwords" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Value</th>
          <th>Category</th>
          <th class="text-center">Times Used</th>
          <th class="text-center">Active</th>
          <th class="text-right">Created At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="badword in badwords" :key="`badwords-tr-${badword.id}`" :value="badword">
          <td>{{ badword.id }}</td>
          <td>{{ badword.value }}</td>
          <td>
            <router-link :to="{ name: 'badwords-categories-single', params: { id: badword.category.id } }">{{ badword.category.name }}</router-link>
          </td>
          <td class="text-center">
            <badge title="Total">{{ badword.times }}</badge>
          </td>
          <td class="text-center">
            <badge-active :value="badword.active"/>
          </td>
          <td class="text-right">{{ badword.created_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'badwords-edit', params: { id: badword.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'badwords-single', params: { id: badword.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import Badge from '@/views/components/simple/Badge'
import BadgeActive from '@/views/components/simple/BadgeActive'

export default {
  name: 'BadwordsList',
  metaInfo: {
    title: 'Badwords'
  },
  components: {
    BoxTable,
    Btn,
    Badge,
    BadgeActive
  },
  data () {
    return {
      badwords: []
    }
  }
}
</script>
